<template>
  <div>
    <plan-waybill ></plan-waybill>
  </div>
</template>
<script>
import RegularWaybill from './RegularWaybill.vue'
import PlanWaybill from './PlanWaybill.vue'
export default {
  components: {
    RegularWaybill,
    PlanWaybill
  },
  data() {
    return {
      status:false
    }
  },
  beforeRouteLeave(to,from,next) {
    console.log(from);
    console.log(to);
    if (to.name=='PlanDetails') {
      if (!from.meta.KeepAlive) {
        from.meta.KeepAlive = true
      }
      next();
    } else {
      from.meta.KeepAlive = false
      // to.meta.KeepAlive = false
      this.$destroy();
      next()
    }
  }
}
</script>
<style lang="scss" scoped>
html,body {
  height: 100vh;
}
</style>